<template>
  <div>
    <b-card header-tag="header">
      <b-row>
        <b-col cols="12">
          <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col cols="2">
                <i class="fa fa-database"></i> Proje Listesi
                <img v-if="d_timerControlProjectUpdate.status === 'active'" src="@/icon/sandwatch.gif" style="width: 1.5em;" />
              </b-col>
              <b-col cols="2">
                <b-form-input v-model="d_searchProjectText" placeholder="Filtreleme alanı"></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-form-select v-if="user && (user.permissions_result.indexOf('admin') !== -1 || user.permissions_result.indexOf('wisdom') !== -1)" v-model="d_userType">
                  <option value="this">bu kullanıcı</option>
                  <option value="all">tüm</option>
                </b-form-select>
              </b-col>
              <b-col cols="4">
                <b-form-select v-model="d_selectedTab">
                  <option value="project_list">Tüm Projeler</option>
                  <option value="project_list_data_updating_continuing">Veri Hazırlama Devam Eden</option>
                  <option value="project_list_data_updating_cancelled">Veri Hazırlama İptal Edilen</option>
                  <option value="project_list_algorithm_analyzing_continuing">Algoritma Analizi Devam Eden</option>
                  <option value="project_list_data_updating_continuing_last_info_is_five_minutes">Veri Hazırlama Devam eden son bilgilendirme 5 dakika içinde</option>
                  <option value="project_list_data_updating_continuing_last_info_is_older_than_one_hour">Veri Hazırlama Devam eden son bilgilendirme 1 saaten eski</option>
                  <option value="project_last_info_is_older_than_1_day">Son Bilgilendirme 1 günden eski olan projeler</option>
                  <option value="project_last_info_is_older_than_3_day">Son Bilgilendirme 3 günden eski olan projeler</option>
                  <option value="project_last_info_is_older_than_7_day">Son Bilgilendirme 7 günden eski olan projeler</option>
                  <option value="project_last_info_is_older_than_14_day">Son Bilgilendirme 14 günden eski olan projeler</option>
                  <option value="project_auto_yes">Otomatik Başlaması Belirtilen Projeler</option>
                  <option value="project_auto_no">Otomatik Olmayan Projeler</option>
                  <option value="project_positive_algorithm_count_0">Son algoritma analizinde hiç pozitif algoritma tespit edilemeyen projeler</option>
                </b-form-select>
              </b-col>
              <b-col cols="2" style="text-align: right; color: whitesmoke;">
                <b-dropdown variant="link" size="md" no-caret>
                  <template style="" slot="button-content">
                    &#x1f50d;
                  </template>
                  <b-dropdown-item @click="f_addNewWdm3Data()">yeni proje</b-dropdown-item>
                  <b-dropdown-item @click="f_getUserPolestarProjects()"> projeleri yenile </b-dropdown-item>
                  <b-dropdown-item v-if="d_selectedProjectList.length > 0" @click="d_analyzeByLayerDataId.show = true"> Katman Verisi Id Bazlı Analiz </b-dropdown-item>
                  <b-dropdown-item v-if="d_selectedProjectList.length > 0" @click="f_analyzeAlgorithmAndSendResultWithApi()"> Seçili Projelerde Algoritma Analiz Kolonlarını ve Apileri Çalıştır </b-dropdown-item>
                  <!-- <b-dropdown-item v-if="d_selectedProjectList.length > 0" @click="f_cancelProjectUpdate()"> Seçili Projelerdeki İşlemleri Durdur </b-dropdown-item> -->
                  <b-dropdown-item v-if="d_selectedProjectList.length > 0" @click="f_changeDataUpdatingStatus({'value': 'cancelled', 'label': 'iptal edildi'})"> Seçili Proje, Veri Güncelleme Durumu (iptal edildi) </b-dropdown-item>
                  <b-dropdown-item v-if="d_selectedProjectList.length > 0" @click="f_changeDataUpdatingStatus({'value': 'not_started', 'label': 'henüz başlamadı'})"> Seçili Proje, Veri Güncelleme Durumu (Henüz Başlamadı) </b-dropdown-item>
                  <b-dropdown-item v-if="d_selectedProjectList.length > 0" @click="f_projectSettingsAutoTime('auto_distribute_in_a_day_by_hours_one_time')"> Seçili Proje, Gün İçi Başlama Saatini Otomatik Dağıt, Günde Bir Kez </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card-header>
          <div :style="f_calculateTableStyle()">
            <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
              <thead>
                <tr>
                  <th class="sticky_th">
                    <button @click="f_checkProjects()">seç</button>
                  </th>
                  <th class="sticky_th">No</th>
                  <th class="sticky_th" style="width: 200px!important;">
                    <img src="@/icon/2930471.png" style="width: 1.5em;" /> Proje Adı
                  </th>
                  <!-- <th class="sticky_th" title="Son Yeni Veri Hazırlama Tarihi">Son Yeni Veri Haz Tar.</th> -->
                  <th class="sticky_th" title="Son veri Güncelleme tipi">Son veri günc. tipi</th>
                  <!-- <th class="sticky_th" title="Son Bilgilendirme Tarihi">Son Bilgi Tar.</th> -->
                  <th class="sticky_th" title="Uygulama">Uygulama</th>
                  <th class="sticky_th" title="Proje Veri Tipi">Proje Veri Tipi</th>
                  <th class="sticky_th" title="Otomatik">Otomatik</th>
                  <th class="sticky_th" title="Otomatik Veri Hazırlama Zamanı Geçti mi?">Otom. Veri Haz. Zamanı Geçti mi?</th>
                  <th class="sticky_th" title="Kaynak Uygunluğu (CPU, RAM gibi değerlerin yeterliliğini kontrol eder.)">Kaynak Uygunluğu</th>
                  <th class="sticky_th" title="Kaynak Uygunluğu CPU Maksimum Yüzde">CPU</th>
                  <th class="sticky_th" title="Kaynak Uygunluğu RAM Maksimum Yüzde">RAM</th>
                  <th class="sticky_th" title="Saat Aralığı">Saat Aralığı</th>
                  <th class="sticky_th" title="Belirtilen Saatler">Belirtilen Saatler</th>
                  <th class="sticky_th" title="İlk Saat">İlk Saat</th>
                  <th class="sticky_th" title="İkinci Saat">İkinci Saat</th>
                  <th class="sticky_th" title="İnterval Değeri">İnterval Değeri</th>
                  <th class="sticky_th" title="İnterval Tipi">İnterval Tipi</th>
                  <th class="sticky_th" title="Veri Güncelleme Tipi">Veri Günc. Tipi</th>
                  <th class="sticky_th" title="Son Bilgilendirme Kaç Dk Önce">Son Bilgi Kaç Dk Önce</th>
                  <th class="sticky_th" title="Veri Güncelleme Durumu">Veri Günc. Durumu</th>
                  <th class="sticky_th" title="Veri Güncellme İşlem Başlama Tarihi">Veri Günc. Baş. Kaç Dk Önce</th>
                  <th class="sticky_th" title="Veri Güncellme İşlem Bitiş Tarihi">Veri Günc. Bit. Kaç Dk Önce</th>
                  <th class="sticky_th" title="Veri Güncellme İşlemi Kaç Dakika Sürdü">Veri Günc. Kaç Dk Sürdü</th>
                  <th class="sticky_th" title="Algoritma Analiz Durumu">Algo Durumu</th>
                  <th class="sticky_th" title="Algo Analiz İşlem Başlama Tarihi">Algo Analiz. Baş. Kaç Dk Önce</th>
                  <th class="sticky_th" title="Algo Analiz İşlem Bitiş Tarihi">Algo Analiz. Bit. Kaç Dk Önce</th>
                  <th class="sticky_th" title="Algo Analiz İşlemi Kaç Dakika Sürdü">Algo Analiz. Kaç Dk Sürdü</th>
                  <th class="sticky_th" title="Son Pozitif Algoritma Adedi">Son Poz. Algo. Adedi</th>
                  <th class="sticky_th" title="Toplam Dataset Adedi">Toplam Dataset Adedi</th>
                  <th class="sticky_th" title="Tamamlanan Dataset Adedi">Tamamlanan Dataset Adedi</th>
                  <th class="sticky_th" title="Seçenek Algoritma">Seçenek Algoritma</th>
                  <th class="sticky_th" title="İşlem">İşlem</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(project, project_ind) in d_projectList">
                  <tr v-if="f_searchProject(project) && project.project_data" :style="d_selectedProjectInd === project_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px;' : 'cursor: pointer; padding: 5px;'" @click="f_selectThisProject(project_ind)" @dblclick="go_to_this_project_details(project.id)">
                    <td>
                      <b-form-checkbox v-model="d_selectedProjectList" :value="project.project_data.id"></b-form-checkbox>
                    </td>
                    <td>
                      <strong>{{ project_ind + 1 }} -</strong>
                    </td>
                    <td style="width: 150px!important;" :title="project.project_data.label">
                      {{ project.project_data.label.substring(0, 30) }} ...
                    </td>
                    <!--                     <td>
                      {{ project.project_data.data.general.last_completed_add_new_cases_date ? DateFormat(project.project_data.data.general.last_completed_add_new_cases_date.val) : '' }}
                    </td>
 -->
                    <td :title="project.project_data.data.general && project.project_data.data.general.last_data_update_type ? project.project_data.data.general.last_data_update_type.val.label : ''">
                      {{ project.project_data.data.general.last_data_update_type ? project.project_data.data.general.last_data_update_type.val.label.substring(0,15) + '...' : ''}}
                    </td>
                    <!--                     <td>
                      {{ project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val ? DateFormat(project.project_data.data.general.last_info_date.val) : '' }}
                    </td>
 -->
                    <td>
                      {{ project.project_data.data.general.process_application ? project.project_data.data.general.process_application.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.general.project_data_type ? project.project_data.data.general.project_data_type.val.label : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.auto_update ? project.project_data.data.settings.auto_update.val.label : '' }}
                    </td>
                    <td>
                      {{ project.auto_update_problem ? project.auto_update_problem.label : '' }}
                    </td>
                    <td :title="project.project_data.data.settings && project.project_data.data.settings.resource_behaviour ? project.project_data.data.settings.resource_behaviour.val.label : ''">
                      {{ project.project_data.data.settings && project.project_data.data.settings.resource_behaviour && project.project_data.data.settings.resource_behaviour.val ? project.project_data.data.settings.resource_behaviour.val.label.substring(0,10) + '..' : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.resource_cpu_max_percent ? project.project_data.data.settings.resource_cpu_max_percent.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.resource_ram_max_percent ? project.project_data.data.settings.resource_ram_max_percent.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.hour_time_range ? project.project_data.data.settings.hour_time_range.val.label : '' }}
                    </td>
                    <td>
                      <template v-if="project.project_data.data.settings && project.project_data.data.settings.defined_hours && project.project_data.data.settings.defined_hours.val && project.project_data.data.settings.defined_hours.val.length > 0">
                        <span v-for="(h, h_index) in project.project_data.data.settings.defined_hours.val">
                          {{ h.label }} , 
                        </span>
                      </template>
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.firsthour ? project.project_data.data.settings.firsthour.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.secondhour ? project.project_data.data.settings.secondhour.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.settings && project.project_data.data.settings.interval_value ? project.project_data.data.settings.interval_value.val : '' }}
                    </td>
                    <td :title="project.project_data.data.settings && project.project_data.data.settings.interval_type ? project.project_data.data.settings.interval_type.val.label : ''">
                      {{ project.project_data.data.settings && project.project_data.data.settings.interval_type ? project.project_data.data.settings.interval_type.val.label.substring(0,10) + '..' : '' }}
                    </td>
                    <td :title="project.project_data.data.settings && project.project_data.data.settings.update_type && project.project_data.data.settings.update_type.val ? project.project_data.data.settings.update_type.val.label : ''">
                      {{ project.project_data.data.settings && project.project_data.data.settings.update_type && project.project_data.data.settings.update_type ? project.project_data.data.settings.update_type.val.label.substring(0,15) + '...' : '' }}
                    </td>
                    <td :style="f_styleCell('last_info_date_minutes_dif', project.project_data)" :title="project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val ? DateFormat(project.project_data.data.general.last_info_date.val) : ''">
                      <div v-if="project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) ? DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) : '' }}</div>
                    </td>
                    <td :style="f_styleCell('data_updating_status', project.project_data)">
                      {{ project.project_data.data.general.data_updating_status ? project.project_data.data.general.data_updating_status.val.label : '' }}
                    </td>
                    <td>
                      <div v-if="project.project_data.data.general.process_start_date_of_data_update && project.project_data.data.general.process_start_date_of_data_update.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_data_update.val) ? DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_data_update.val) : '' }}</div>
                    </td>
                    <td>
                      <div v-if="project.project_data.data.general.process_end_date_of_data_update && project.project_data.data.general.process_end_date_of_data_update.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_data_update.val) ? DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_data_update.val) : '' }}</div>
                    </td>
                    <td>
                      <div v-if="project.project_data.data.general.process_start_date_of_data_update && project.project_data.data.general.process_start_date_of_data_update.val && project.project_data.data.general.process_end_date_of_data_update && project.project_data.data.general.process_end_date_of_data_update.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_data_update.val) && DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_data_update.val) ? (DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_data_update.val) - DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_data_update.val)) : '' }}</div>
                    </td>
                    <td :style="f_styleCell('algorithm_analyzing_status', project.project_data)">
                      {{ project.project_data.data.general.algorithm_analyzing_status ? project.project_data.data.general.algorithm_analyzing_status.val.label : '' }}
                    </td>
                    <td>
                      <div v-if="project.project_data.data.general.process_start_date_of_algorithm_analyze && project.project_data.data.general.process_start_date_of_algorithm_analyze.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_algorithm_analyze.val) ? DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_algorithm_analyze.val) : '' }}</div>
                    </td>
                    <td>
                      <div v-if="project.project_data.data.general.process_end_date_of_algorithm_analyze && project.project_data.data.general.process_end_date_of_algorithm_analyze.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_algorithm_analyze.val) ? DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_algorithm_analyze.val) : '' }}</div>
                    </td>
                    <td>
                      <div v-if="project.project_data.data.general.process_start_date_of_algorithm_analyze && project.project_data.data.general.process_start_date_of_algorithm_analyze.val && project.project_data.data.general.process_end_date_of_algorithm_analyze && project.project_data.data.general.process_end_date_of_algorithm_analyze.val"> {{ DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_algorithm_analyze.val) && DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_algorithm_analyze.val) ? (DateFunctions.minute_dif('now', project.project_data.data.general.process_start_date_of_algorithm_analyze.val) - DateFunctions.minute_dif('now', project.project_data.data.general.process_end_date_of_algorithm_analyze.val)) : '' }}</div>
                    </td>
                    <td>
                      {{ project.project_data.data.general.positive_algorithm_count ? project.project_data.data.general.positive_algorithm_count.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.general.active_process_dataset_total_count ? project.project_data.data.general.active_process_dataset_total_count.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.general.active_process_dataset_completed_count ? project.project_data.data.general.active_process_dataset_completed_count.val : '' }}
                    </td>
                    <td>
                      {{ project.project_data.data.general.data_option_algorithm_status ? project.project_data.data.general.data_option_algorithm_status.val.label : '' }}
                    </td>
                    <td>
                      <b-dropdown variant="link" size="lg" no-caret>
                        <template style="" slot="button-content">
                          &#x1f50d;
                        </template>
                        <!-- <b-dropdown-item @click="f_editProject(project_ind)"> Projeyi düzenle </b-dropdown-item> -->
                        <!-- <b-dropdown-item @click="go_to_this_project_details(project.id)"> Proje Detaylarına Git </b-dropdown-item> -->
                        <b-dropdown-item @click="go_to_this_project_details(project.id)"> Wanalyzer Projesine Git </b-dropdown-item>
                        <b-dropdown-item @click="f_deletePolestarProject(project.id)"> Projeyi Sil </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_showWisdomDataModal" @close="d_showWisdomDataModal = false" :width="'750'">
      <h3 slot="header">{{ $t('wdm16.16999') }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_savePoleStarProject()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showWisdomDataModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_analyzeByLayerDataId.show" @close="d_analyzeByLayerDataId.show = false" :width="'1000'">
      <h3 slot="header">Katman Veri Id İle Analiz</h3>
      <div slot="body">
        <b-row>
          <b-col cols="6">Yanıt Tipi</b-col>
          <b-col cols="6">
            <select v-model="d_analyzeByLayerDataId.response_type" class="form-control">
              <option value="excel">excel</option>
              <!-- <option value="row_mode">satır mod</option> -->
              <option value="column_mode">kolon mod</option>
            </select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">Vaka Ana Kural Algoritması Analiz Edilsin mi ?</b-col>
          <b-col cols="6">
            <select v-model="d_analyzeByLayerDataId.case_algorithm" class="form-control">
              <option value="yes">evet</option>
              <option value="no">hayır</option>
            </select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">Wdmr Key Listesi (virgül ile)</b-col>
          <b-col cols="6">
            <input type="text" class="form-control" v-model="d_analyzeByLayerDataId.wdmr_key">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">Kolon Adı (virgül ile)</b-col>
          <b-col cols="6">
            <input type="text" class="form-control" v-model="d_analyzeByLayerDataId.column_name">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">Client Id Listesi (virgül ile)</b-col>
          <b-col cols="6">
            <input type="text" class="form-control" v-model="d_analyzeByLayerDataId.client_id">
          </b-col>
        </b-row>
        <b-row v-if="d_analyzeByLayerDataId.result">
          <b-col cols="12">
            <template v-if="d_analyzeByLayerDataId.result.length > 0">
              <template v-for="(res, res_ind) in d_analyzeByLayerDataId.result">
                <b-row style="margin: 3px;">
                  <b-col cols="12"><strong>{{ res.name }}</strong></b-col>
                </b-row>
                <template v-for="(row, row_index) in res.rows">
                  <b-row style="margin: 3px; background-color: #d0fffe;" @click="f_clickThisRow(row_index)">
                    <b-col cols="1"><strong>{{ row_index + 1 }}</strong></b-col>
                    <b-col cols="11">{{ row.client_id }} ( {{ row.id }} )</b-col>
                  </b-row>
                  <template v-if="d_selectedRowList.indexOf(row_index) !== -1" v-for="(col, col_index) in row.columns">
                    <b-row style="margin: 3px; border-bottom: solid 1px skyblue;">
                      <b-col cols="6">
                        {{ col.name }}
                      </b-col>
                      <b-col cols="6">
                        <template v-for="(obj, obj_key) in col.obj">
                          <template v-if="obj.list && obj.list.length > 0">
                            <template v-for="(li, li_ind) in obj.list">
                              <div v-if="li.val && li.val.label">
                                {{ li.val.label }}
                              </div>
                              <div v-else>
                                {{ li.val }}
                              </div>
                            </template>
                          </template>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </template>
            </template>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_analyzeByLayerDataId()">analiz</button>
        <button type="button" class="btn btn-danger" @click="d_analyzeByLayerDataId.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import DecisionMakingService from '@/services/decision_making';
import FileSaver from 'file-saver';
import { API_FRONTEND_URL } from '@/config';
import { ClsPolestar } from "@/services/public/polestar";
import { DateFunctions } from "@/services/public/date_functions";
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import { ClsWdmrForever } from "@/services/public/wdm";
import PoleStarService from '@/services/polestar';
import LayerService from '@/services/layer';
import moment from 'moment';
export default {
  name: 'PolestarProjectList',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    Modal,
    WisdomDataModal,
    WisdomDataShow
  },
  props: {},
  data () {
    return {
      d_selectedRowList: [],
      d_analyzeByLayerDataId: {
        'case_algorithm': "no",
        'column_name': "",
        'response_type': "column_mode",
        'layer_id': "",
        'username': "",
        'project_id': "",
        'wdmr_key': "",
        'client_id': "",
        'show': false,
        "result": "",
      },
      d_selectedProjectList: [],
      d_userType: 'this',
      DateFunctions: DateFunctions,
      d_selectedTab: 'project_list',
      d_timerControlProjectUpdate: { 'timer': 0, 'status': 'passive' },
      d_selectedAnatomySchemaList: [],
      d_selectedProjectInd: '',
      d_searchProjectText: '',
      wisdom_data: {},
      user: {},
      option_data: {},
      wisdom_op: 'new',
      data_type: '',
      d_showWisdomDataModal: false,
      d_projectList: [],
      d_Wdm22_data: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_Wdm22_data = JSON.parse(localStorage.getItem('wdm22'));
    let query = 'data_type=wdm3&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm3'] = resp.data.result;
        } else {
          console.log('errror ', resp.data.message);
        }
      });
    this.f_getUserPolestarProjects();
  },
  beforeMount () {},
  mounted: function () {
    let div = document.getElementById('search-project-text');
    if (div) {
      div.focus();
    }
    this.d_timerControlProjectUpdate.timer = setInterval(function () {
      // console.log("this.d_project_id ", this.d_project_id);
      if (this.d_timerControlProjectUpdate.status === 'passive') {
        this.f_getUserPolestarProjects();
      }
    }.bind(this), 30000);
  },
  destroyed () {
    window.clearInterval(this.d_timerControlProjectUpdate.timer);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_clickThisRow: function (row_index) {
      if (this.d_selectedRowList.indexOf(row_index) === -1) {
        this.d_selectedRowList.push(row_index);
      } else {
        this.d_selectedRowList.splice(this.d_selectedRowList.indexOf(row_index), 1);
      }
      this.$forceUpdate();
    },
    f_calculateTableStyle: function (cell_value, project_data) {
      let style = '';
      style += 'overflow-x: hidden;';
      style += 'overflow-y: auto;';
      style += 'height: ' + (window.innerHeight - 175) + 'px;';
      return style;
    },
    f_styleCell: function (cell_value, project_data) {
      let style = '';
      let data_updating_status = '';
      let algorithm_analyzing_status = '';
      let data_option_algorithm_status = '';
      try {
        data_updating_status = project_data.data.general.data_updating_status.val.value;
      } catch (err) {}
      try {
        algorithm_analyzing_status = project_data.data.general.algorithm_analyzing_status.val.value;
      } catch (err) {}
      try {
        data_option_algorithm_status = project_data.data.general.data_option_algorithm_status.val.value;
      } catch (err) {}

      if (cell_value === 'algorithm_analyzing_status') {
        if (algorithm_analyzing_status) {
          if (algorithm_analyzing_status === 'completed') {
            //
          } else if (algorithm_analyzing_status === 'continuing') {
            style += 'background-color: #92f192;';
          } else {
            style += 'background-color: #f1a992;';
          }
        }
      } else if (cell_value === 'data_updating_status') {
        if (data_updating_status) {
          if (data_updating_status === 'completed') {
            //
          } else if (data_updating_status === 'continuing') {
            style += 'background-color: #92f192;';
          } else {
            style += 'background-color: #f1a992;';
          }
        }
      } else if (cell_value === 'last_info_date_minutes_dif') {
        if (data_updating_status === 'continuing' || algorithm_analyzing_status === 'continuing' || data_option_algorithm_status === 'continuing') {
          style += 'background-color: #92f192;';
        }
        /*        let how_many_minutes = '';
                try {
                  how_many_minutes = DateFunctions.minute_dif('now', project_data.data.general.last_info_date.val);
                } catch (err) {}
                if (how_many_minutes !== '') {
                  if (how_many_minutes < 10) {
                    style += 'background-color: #92f192;';
                  } else {
                    style += 'background-color: #f1a992;';
                  }
                } else {
                  style += 'background-color: white;';
                }
        */
      }
      return style;
    },
    f_analyzeAlgorithmAndSendResultWithApi: function () {
      if (this.d_selectedProjectList.length > 0) {
        if (confirm('Seçili projelerde işlem yapmaya devam etmeyi onaylıyor musunuz ?')) {
          for (let i in this.d_selectedProjectList) {
            let data = {
              'col_type': 'all', // selected or all
              'wdm3_id': this.d_selectedProjectList[i],
              'column_id_list': [],
              'response_type': '1'
            };
            DecisionMakingService.analyze_algorithm_and_send_result_with_api(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                let msg = '';
                if (resp.data.status === 'success') {
                  //
                } else {
                  msg = resp.data.message;
                  let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi analiz işlemi hakkında' };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                }
              });
          }
        }
      } else {
        alert('Lütfen proje seçiniz.');
      }
    },
    f_cancelProjectUpdate: function () {
      if (this.d_selectedProjectList.length > 0) {
        if (confirm('Seçili projelerde işlem yapmaya devam etmeyi onaylıyor musunuz ?')) {
          for (let i in this.d_selectedProjectList) {
            let data = {
              'wdm3_id': this.d_selectedProjectList[i],
            };
            PoleStarService.prepare_project_cases_cancelled(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                let msg = '';
                if (resp.data.status === 'success') {
                  //
                } else {
                  msg = resp.data.message;
                  let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlemlerin iptali hakkında' };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                }
              });
          }
        }
      } else {
        alert('Lütfen proje seçiniz.');
      }
    },
    f_projectSettingsAutoTime: function (auto_time_type) {
      if (this.d_selectedProjectList.length > 0) {
        if (confirm('Seçili projelerde işlem yapmaya devam etmeyi onaylıyor musunuz ?')) {
          let query = 'auto_time_type=' + auto_time_type;
          let data = {
            'project_id_list': this.d_selectedProjectList,
          };
          PoleStarService.project_settings_auto_time(query, data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === '2000') {
                let msg = 'İşlem tamamlandı';
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem Hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
                this.f_getUserPolestarProjects();
              } else {
                let msg = resp.data.message;
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlemlerin iptali hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              }
            });
        }
      } else {
        alert('Lütfen proje seçiniz.');
      }
    },
    f_changeDataUpdatingStatus: function (new_status) {
      // console.log('this.d_selectedProjectList : ', this.d_selectedProjectList);
      if (this.d_selectedProjectList.length > 0) {
        if (confirm('Seçili projelerde işlem yapmaya devam etmeyi onaylıyor musunuz ?')) {
          for (let i in this.d_selectedProjectList) {
            for (let project_index in this.d_projectList) {
              let project_data = this.d_projectList[project_index].project_data;
              if (project_data.id === this.d_selectedProjectList[i]) {
                project_data.data.general.data_updating_status = { 'val': new_status };
                // console.log('project_data : ', project_data);
                let data = { 'data': project_data };
                PoleStarService.save_polestar_project(data)
                  .then(resp => {
                    // this.$store.commit('Loading', { 'status': false, 'data': {} });
                    if (resp.data.status_code === "2000") {

                    } else {
                      let modal_data = { 'type': 'alert', 'text': resp.data.status_code + ' ' + resp.data.status_message, 'centered': true, 'title': this.$t('wdm16.13274') };
                      this.$store.commit('modal', modal_data);
                      this.$store.commit('modal_show', true);
                    }
                    this.$forceUpdate();
                  });
                break
              }
            }
          }
        }
      } else {
        alert('Lütfen proje seçiniz.');
      }
    },
    f_analyzeByLayerDataId: function () {
      if (this.d_analyzeByLayerDataId.client_id || this.d_analyzeByLayerDataId.wdmr_key) {
        if (this.d_selectedProjectList.length > 0) {
          let query = '';
          if (this.d_analyzeByLayerDataId.column_name) {
            if (query) {
              query += '&';
            }
            query += 'column_name=' + this.d_analyzeByLayerDataId.column_name;
          }
          if (this.d_analyzeByLayerDataId.response_type) {
            if (query) {
              query += '&';
            }
            query += 'response_type=' + this.d_analyzeByLayerDataId.response_type;
          }
          if (this.d_analyzeByLayerDataId.layer_id) {
            if (query) {
              query += '&';
            }
            query += 'layer_id=' + this.d_analyzeByLayerDataId.layer_id;
          }
          if (this.d_analyzeByLayerDataId.username) {
            if (query) {
              query += '&';
            }
            query += 'username=' + this.d_analyzeByLayerDataId.username;
          }
          if (this.d_analyzeByLayerDataId.client_id) {
            if (query) {
              query += '&';
            }
            query += 'client_id=' + this.d_analyzeByLayerDataId.client_id;
          }
          if (this.d_analyzeByLayerDataId.wdmr_key) {
            if (query) {
              query += '&';
            }
            query += 'wdmr_key=' + this.d_analyzeByLayerDataId.wdmr_key;
          }
          if (this.d_analyzeByLayerDataId.case_algorithm) {
            if (query) {
              query += '&';
            }
            query += 'case_algorithm=' + this.d_analyzeByLayerDataId.case_algorithm;
          }
          if (this.d_selectedProjectList.length > 0) {
            if (query) {
              query += '&';
            }
            query += 'project_id=' + this.d_selectedProjectList.join(',');
          }
          let data = {};
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşleminiz devam ediyor. Lütfen bekleyiniz.' } });
          PoleStarService.analyze_by_layer_data_id(query, data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              // console.log(resp.data);
              if (resp.data.status_code === "2000") {
                if (resp.data.excel) {
                  FileSaver.saveAs(resp.data.excel, "AnalyzeByLayerDataId.xlsx");
                  this.d_analyzeByLayerDataId.show = false;
                } else {
                  this.d_analyzeByLayerDataId.result = resp.data.list;
                }
              } else {
                alert(resp.data.status_code, ' ', resp.data.status_message);
              }
              this.$forceUpdate();
            });
        }

      } else {
        alert('wdmr key veya client id yazınız.');
      }
    },
    f_checkProjects: function (project) {
      if (this.d_selectedProjectList.length > 0) {
        this.d_selectedProjectList = [];
      } else {
        this.d_selectedProjectList = [];
        for (let i in this.d_projectList) {
          if (this.f_searchProject(this.d_projectList[i])) {
            this.d_selectedProjectList.push(this.d_projectList[i].project_data.id);
          }
        }
      }
      this.$forceUpdate();
    },
    f_searchProject: function (project) {
      if (!this.d_searchProjectText) {
        if (this.d_selectedTab === 'project_list') {
          return true;
        } else if (this.d_selectedTab === 'project_list_data_updating_continuing') {
          if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'continuing') {
            return true;
          }
        } else if (this.d_selectedTab === 'project_list_data_updating_cancelled') {
          if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'cancelled') {
            return true;
          }
        } else if (this.d_selectedTab === 'project_list_algorithm_analyzing_continuing') {
          if (project && project.project_data && project.project_data.data.general.algorithm_analyzing_status && project.project_data.data.general.algorithm_analyzing_status.val.value === 'continuing') {
            return true;
          }
        } else if (this.d_selectedTab === 'project_list_data_updating_continuing_last_info_is_five_minutes') {
          if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'continuing') {
            if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val && DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) <= 5) {
              return true;
            }
          }
        } else if (this.d_selectedTab === 'project_list_data_updating_continuing_last_info_is_older_than_one_hour') {
          if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'continuing') {
            if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val && DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 60) {
              return true;
            }
          }
        } else if (this.d_selectedTab === 'project_last_info_is_older_than_1_day') {
          if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
            if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 1440) {
              return true;
            }
          }
        } else if (this.d_selectedTab === 'project_last_info_is_older_than_3_day') {
          if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
            if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 4320) {
              return true;
            }
          }
        } else if (this.d_selectedTab === 'project_last_info_is_older_than_7_day') {
          if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
            if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 10080) {
              return true;
            }
          }
        } else if (this.d_selectedTab === 'project_last_info_is_older_than_14_day') {
          if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
            if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 20160) {
              return true;
            }
          }
        } else if (this.d_selectedTab === 'project_auto_yes') {
          if (project.project_data && project.project_data.data.settings && project.project_data.data.settings.auto_update && project.project_data.data.settings.auto_update.val.value === 'yes') {
            return true;
          }
        } else if (this.d_selectedTab === 'project_auto_no') {
          let is_auto = false;
          if (project.project_data && project.project_data.data.settings && project.project_data.data.settings.auto_update && project.project_data.data.settings.auto_update.val.value === 'yes') {
            is_auto = true;
          }
          if (!is_auto) {
            return true;
          }
        } else if (this.d_selectedTab === 'project_positive_algorithm_count_0') {
          if (project.project_data && project.project_data.data.general && project.project_data.data.general.positive_algorithm_count && project.project_data.data.general.positive_algorithm_count.val) {
            if (parseInt(project.project_data.data.general.positive_algorithm_count.val) === 0) {
              return true;
            }
          }
        }
      } else {
        if (project.config) {
          if (this.d_selectedTab === 'project_list') {
            if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
              return true;
            }
          } else if (this.d_selectedTab === 'project_list_data_updating_continuing') {
            if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'continuing') {
              if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                return true;
              }
            }
          } else if (this.d_selectedTab === 'project_list_data_updating_cancelled') {
            if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'cancelled') {
              if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                return true;
              }
            }
          } else if (this.d_selectedTab === 'project_list_algorithm_analyzing_continuing') {
            if (project && project.project_data && project.project_data.data.general.algorithm_analyzing_status && project.project_data.data.general.algorithm_analyzing_status.val.value === 'continuing') {
              if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                return true;
              }
            }
          } else if (this.d_selectedTab === 'project_list_data_updating_continuing_last_info_is_five_minutes') {
            if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'continuing') {
              if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val && DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) <= 5) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          } else if (this.d_selectedTab === 'project_list_data_updating_continuing_last_info_is_older_than_one_hour') {
            if (project && project.project_data && project.project_data.data.general.data_updating_status && project.project_data.data.general.data_updating_status.val.value === 'continuing') {
              if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val && DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 60) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          } else if (this.d_selectedTab === 'project_last_info_is_older_than_1_day') {
            if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
              if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 1440) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          } else if (this.d_selectedTab === 'project_last_info_is_older_than_3_day') {
            if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
              if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 4320) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          } else if (this.d_selectedTab === 'project_last_info_is_older_than_7_day') {
            if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
              if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 10080) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          } else if (this.d_selectedTab === 'project_last_info_is_older_than_14_day') {
            if (project.project_data && project.project_data.data.general.last_info_date && project.project_data.data.general.last_info_date.val) {
              if (DateFunctions.minute_dif('now', project.project_data.data.general.last_info_date.val) >= 20160) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          } else if (this.d_selectedTab === 'project_auto_yes') {
            if (project.project_data && project.project_data.data.settings.auto_update && project.project_data.data.settings.auto_update.val.value === 'yes') {
              if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                return true;
              }
            }
          } else if (this.d_selectedTab === 'project_auto_no') {
            let is_auto = false;
            if (project.project_data && project.project_data.data.settings.auto_update && project.project_data.data.settings.auto_update.val.value === 'yes') {
              is_auto = true;
            }
            if (!is_auto) {
              if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                return true;
              }
            }
          } else if (this.d_selectedTab === 'project_positive_algorithm_count_0') {
            if (project.project_data && project.project_data.data.general && project.project_data.data.general.positive_algorithm_count && project.project_data.data.general.positive_algorithm_count.val) {
              if (parseInt(project.project_data.data.general.positive_algorithm_count.val) === 0) {
                if (project.project_data.label.toString().toLocaleLowerCase().indexOf(this.d_searchProjectText.toLocaleLowerCase()) !== -1) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    },
    f_goToWisdomStats: function (project_id) {
      this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
    },
    f_deletePolestarProject: function (wdm3_id) {
      let modal_data = { 'type': 'confirm', 'text': 'Projeyi silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Proje Silme İşlemi Hakkında', 'function_name': 'f_deletePolestarProjectConfirmed', 'confirm': false, 'arguments': [wdm3_id] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_deletePolestarProjectConfirmed: function (args) {
      let data = { 'wdm3_id': args[0] };
      PoleStarService.delete_polestar_project(data)
        .then(resp => {
          if (resp.data.status === "success") {
            let del = '';
            for (let i in this.d_projectList) {
              if (this.d_projectList[i].id === args[0]) {
                del = i;
              }
            }
            this.d_projectList.splice(del, 1);
            this.$forceUpdate();
          } else {}
          this.$forceUpdate();
        });
    },
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY HH:mm');
      } else {
        return '';
      }
    },
    go_to_this_project_details: function (project_id) {
      // this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
      let url = API_FRONTEND_URL + '#/polestar/wisdomstats?project_id=' + project_id;
      window.open(url, '_blank');
      // this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
      // this.$router.push({ path: '/polestar/projects/details', query: { project_id: project_id } });
    },
    f_scrollCaseTable: function () {
      var content = document.getElementById("case-table");
      // var scrolled = document.scrollingElement.scrollTop;
      // console.log('scrolled', scrolled);
      var position = content.offsetTop;
      var scrollHeight = content.scrollHeight;
      console.log('position', position);
      console.log('scrollHeight', scrollHeight);
    },
    f_selectThisProject: function (project_ind) {
      this.d_selectedProjectInd = project_ind;
      this.$forceUpdate();
    },
    f_editProject: function (project_ind) {
      this.wisdom_op = "edit";
      this.data_type = 'wdm3';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_projectList[project_ind]));
      this.d_showWisdomDataModal = true;
    },
    f_getUserPolestarProjects: function () {
      this.d_timerControlProjectUpdate.status = 'active';
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
      ClsPolestar.f_getUserPolestarProjects(this.user.username, 'general', this.d_userType)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          // console.log(resp);
          this.d_projectList = resp;
          this.d_timerControlProjectUpdate.status = 'passive';
          this.$forceUpdate();
        }, resp => {
          alert('error : ', resp);
        });
    },
    f_savePoleStarProject: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'require']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let data = {};
      data = { 'data': this.wisdom_data };
      data.permission_control = { 'value': 'wanalyzer_create-project', 'i_used_this_permission': 1 };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje kaydediliyor. Lütfen bekleyiniz.' } });
      this.d_showWisdomDataModal = false;
      PoleStarService.save_polestar_project(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === "success") {
            this.f_getUserPolestarProjects();
            // this.d_projectList = resp.data.result.project_list;
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_addNewWdm3Data: function () {
      let data = { 'wdm22_id': this.d_Wdm22_data.id };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      LayerService.get_user_layer_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (resp.data.result.wdm246_list && resp.data.result.wdm246_list.length > 0) {
              this.option_data['wdm3']['general']['parameters']['layer']['options'] = resp.data.result.wdm246_list;
            }
            this.data_type = 'wdm3';
            this.wisdom_op = "new";
            let pre_data = {
              'owner': this.user.username,
              'id': 'new',
              'data': {},
              'type': 'wdm3',
              'parent': 'wdm3',
              'reference_wdm': {}
            };
            pre_data['reference_wdm']['version'] = this.option_data['wdm3']['version'];
            pre_data['reference_wdm']['owner_type'] = this.option_data['wdm3']['owner_type'];
            if (this.option_data['wdm3']['owner_type'] === 'wisdom') {
              pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm3_v' + this.option_data['wdm3'].version;
            }
            for (let i in this.option_data['wdm3'].param_group.param_order) {
              pre_data['data'][this.option_data['wdm3'].param_group.param_order[i]] = {};
            }
            pre_data['data']['general']['status'] = { 'val': { 'label': 'Aktif', 'value': 'active' } }
            pre_data['data']['general']['location'] = {
              'val': {
                'translation': {
                  'en': 'Lokal',
                  'tr': 'Lokal'
                },
                'value': 'local',
                'label': 'Lokal'
              }
            };
            this.wisdom_data = pre_data;
            this.d_showWisdomDataModal = true;
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deletePolestarProjectConfirmed' && this.StoreModal.data.confirm) {
          this.f_deletePolestarProjectConfirmed(this.StoreModal.data.arguments);
        }
      }
    }
  }
};

</script>

<style>
table,
tr,
th,
td {
  border: solid 0.5px lightseagreen;
  font-size: 10px;
  text-align: center;
}

.sticky_th {
  position: sticky;
  z-index: 1000;
  top: 0;
  text-align: center;
  background-color: #b9e7d8;
}

.parameter-design-full-screen {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.not-parameter-design-full-screen {
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

