<template>
  <div>
    <pole-star-project></pole-star-project>
  </div>
</template>

<script>
import store from '@/store';
import PoleStarProject from '@/components/widgets/PoleStarProject';
import { mapGetters } from 'vuex';

export default {
  name: 'PoleStarParent',
  data () {
    return {};
  },
  components: {
    PoleStarProject
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {},
  mounted () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css"></style>

