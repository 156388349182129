import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save_polestar_dmp_to_wai(data) {
    return Vue.http.post(API_BASE_URL + 'decisionmaking/save_polestar_dmp_to_wai', data);
  },
  get_polestar_dmp_from_wai(data) {
    return Vue.http.post(API_BASE_URL + 'decisionmaking/get_polestar_dmp_from_wai', data);
  },
  analyze_dataset_rows_with_dmp(data) {
    return Vue.http.post(API_BASE_URL + 'decisionmaking/analyze_dataset_rows_with_dmp', data);
  },
  analyze_all_dataset_by_dmp_sms_operation(data) {
    return Vue.http.post(API_BASE_URL + 'decisionmaking/analyze_all_dataset_by_dmp_sms_operation', data);
  },
  analyze_algorithm_and_send_result_with_api(data) {
    return Vue.http.post(API_BASE_URL + 'decisionmaking/analyze_algorithm_and_send_result_with_api', data);
  }
  /*
  get_wdm22_wdm_list (query) {
    return Vue.http.get(API_BASE_URL + 'get_wdm22_wdm_list?' + query);
  },
  save_child_wdmr (data) {
    return Vue.http.put(API_BASE_URL + 'save_child_wdmr', data);
  },
  */
};
